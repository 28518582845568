
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'FiltersDrawer'
})
export default class extends Vue {
  private showFilters = false

  private filters: any = {
    buyer: null,
    year: this.$moment(),
    page: 1,
    limit: 20
  }

  get filtersActive() {
    return Object.keys(this.filters).filter(i => {
      if (this.filters[i] !== null) {
        if ((typeof this.filters[i] === 'object' && this.filters[i].length > 0) || typeof this.filters[i] !== 'object') {
          if (i !== 'page') {
            return i
          }
        }
      }
    }).length
  }

  refresh() {
    this.showFilters = false
    this.$emit('applyFilters', this.filters)
  }
}
