
import { Component, Vue } from 'vue-property-decorator'
import { Buyer, PurchaseInfo } from '@/models'

import BuyerCard from './components/BuyerCard.vue'
import Pagination from '@/components/Pagination/index.vue'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'VolumeBuyers',
  components: {
    BuyerCard,
    Pagination,
    VolumeBuyersHeader: () => import('./components/VolumeBuyersHeader.vue'),
    FiltersDrawer: () => import('./components/FiltersDrawer.vue')
  }
})
export default class extends Vue {
  userStore: any = useUserStore()

  private sellerPI!: PurchaseInfo

  private buyers: Buyer[] = []
  private totalBuyers = 0
  private buyersLoading = true

  private listQuery = {
    buyer: null,
    year: this.$moment(),
    page: 1,
    limit: 20
  }

  get sellerId() {
    return this.userStore.model.manageable.id
  }

  async created() {
    await this.getSellerPurchaseInfo()
    await this.getBuyers()
  }

  private async getBuyers() {
    const { data, meta } = await Buyer
      .where({ name: { prefix: this.listQuery.buyer } })
      .stats({ total: 'count' })
      .all()

    this.buyers = data
    this.totalBuyers = meta.stats.total.count
    this.buyersLoading = false
  }

  private async getSellerPurchaseInfo() {
    const { data } = await PurchaseInfo
      .where({ sellerId: this.sellerId })
      .where({ buyerId: null })
      .includes(['buyer', 'rate'])
      .all()

    this.sellerPI = data.find(e => e.buyer === undefined) || new PurchaseInfo()
  }

  private setFilters(filters: any) {
    this.listQuery = { ...filters }
  }

  private refresh() {
    this.listQuery.buyer = null
    this.getBuyers()
  }
}
