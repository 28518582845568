
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CardDistribution, CardStock, GiftCard, Order } from '@/models'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'StockCard'
})
export default class extends Vue {
  @Prop({ required: true }) private cardStock!: GiftCard

  public userStore: any = useUserStore()

  private orderMode = false
  private order: Order | null = null

  get isWholesaler() {
    return this.userStore.roles.includes('wholesaler')
  }

  get neededStock(): number {
    return this.cardStock.cardStocks.filter(cs => cs.sku && cs.toBuyCount).length
  }

  get neededCumulatedFacevalue(): number {
    return this.cardStock.cardStocks
      .filter(cs => cs.sku && cs.toBuyCount)
      .reduce((acc: number, current: CardStock): number => {
        return acc + (current.sku.faceValue * current.toBuyCount)
      }, 0)
  }

  get cumulatedFaceValue(): number {
    return this.cardStock.cardStocks
      .filter(cs => cs.sku && cs.inStockCount)
      .reduce((acc: number, current: CardStock): number => {
        return acc + (current.sku.faceValue * current.inStockCount)
      }, 0)
  }

  get orderToCreateAmount(): number | undefined {
    return this.order?.cardDistributions
      .reduce((acc: number, current: CardDistribution): number => {
        return acc + (current.sku.faceValue * current.quantity)
      }, 0)
  }

  private formatCardStock(cardStocks: CardStock[]): CardStock[] {
    return cardStocks.filter(cs => cs.sku && !cs.sku.faceValueFree).sort((a, b) => a.sku.faceValue - b.sku.faceValue)
  }

  private formatCardStockNeeded(cardStocks: CardStock[]): CardStock[] {
    return cardStocks.filter(cs => cs.sku && cs.toBuyCount).sort((a, b) => a.sku.faceValue - b.sku.faceValue)
  }

  private async selectToOrder(): Promise<void> {
    this.$emit('selected')
    this.orderMode = true
    this.order = new Order({ giftCard: this.cardStock })
    this.order.cardDistributions = await this.cardDistributionsBySku()
  }

  private async cardDistributionsBySku(): Promise<CardDistribution[]> {
    const cardDistribution = []
    for (const cs of this.formatCardStock(this.cardStock.cardStocks)) {
      if (cs.toBuyCount)
        cardDistribution.push(new CardDistribution({
          quantity: cs.toBuyCount,
          amount: cs.sku.faceValue,
          sku: cs.sku,
          reservedQuantity: this.isWholesaler ? cs.toBuyCount || 0 : 0
        }))
    }
    return cardDistribution
  }

  statusStock(stock: CardStock): void {
    if (this.orderMode) {
      const stockSelectedIndex = this.order?.cardDistributions.findIndex(cd => cd.sku.id === stock.sku.id)

      if (typeof stockSelectedIndex === 'number' && stockSelectedIndex >= 0) {
        this.order!.cardDistributions.splice(stockSelectedIndex, 1)
      } else {
        this.order!.cardDistributions.push(new CardDistribution({
          quantity: stock.toBuyCount,
          amount: stock.sku.faceValue,
          sku: stock.sku
        }))
      }
    }
  }

  private async createOrder(): Promise<void> {
    this.order!.amount = await this.orderToCreateAmount || 0
    this.$emit('createOrder', this.order)
    this.orderMode = false
    this.order = null
  }

  private unselectCardStock(): void {
    this.$emit('unselect')
    this.orderMode = false
    this.order = null
  }
}
