
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Buyer, PurchaseInfo, Volume } from '@/models'

@Component({
  name: 'BuyerCard'
})
export default class extends Vue {
  @Prop({ required: true }) private buyer!: Buyer
  @Prop({ required: true }) private listQuery!: any
  @Prop({ required: true }) private sellerPI!: PurchaseInfo

  private purchaseInfo!: PurchaseInfo
  private volume!: Volume
  private loading = true

  get activeYear() {
    return this.$moment(this.listQuery.year as string).format('YYYY')
  }

  get volumeTrend() {
    return Object.values(this.volume.volumes)
  }

  get isTrendingUp() {
    if (Object.keys(this.volume.volumes).length > 1) return (this.volumeTrend[Object.keys(this.volume.volumes).length - 1] as any) > (this.volumeTrend[Object.keys(this.volume.volumes).length - 2] as any)
    return true
  }

  async created() {
    await this.getPurchaseInfo()
    await this.getVolumes()
    this.loading = false
  }

  private async getPurchaseInfo() {
    const { data } = await PurchaseInfo
      .where({ buyerId: this.buyer.id })
      .includes(['rate'])
      .all()

    this.purchaseInfo = data[0]
  }

  private async getVolumes() {
    const { data } = await Volume.where({ id: this.buyer.id }).all()
    this.volume = data[0]
  }
}
