
import { Component, Vue } from 'vue-property-decorator'
import StockTable from './components/StockTable.vue'

@Component({
  name: 'Stocks',
  components: {
    StockHeader: () => import('./components/StockHeader.vue'),
    StockTable: () => import('./components/StockTable.vue'),
    FiltersDrawer: () => import('./components/FiltersDrawer.vue')
  }
})
export default class extends Vue {
  private listQuery = {
    giftCardId: null,
    orderBy: 'name'
  }

  private setFilters(filters: any) {
    this.listQuery = { ...filters }
  }

  private refreshTable() {
    (this.$refs.stockTable as StockTable).refresh()
  }
}
