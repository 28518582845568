
import { Component, Vue } from 'vue-property-decorator'
import { GiftCard } from '@/models'

@Component({
  name: 'FiltersDrawer',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue')
  }
})
export default class extends Vue {
  private showFilters = false

  private giftCards: GiftCard[] = []
  private giftCardsLoading = false
  private paginationGiftCards = {
    page: 1,
    perPage: 999
  }

  private filters: any = {
    giftCardId: null,
    orderBy: 'name'
  }

  orderByOptions = [
    {
      label: this.$t('stock.header.filters.options.name'),
      value: 'name'
    },
    {
      label: this.$t('stock.header.filters.options.delivery'),
      value: 'delivery'
    }
  ]

  get filtersActive() {
    return Object.keys(this.filters).filter(i => {
      if (this.filters[i] !== null && this.filters[i] !== '') {
        if ((typeof this.filters[i] === 'object' && this.filters[i].length > 0) || typeof this.filters[i] !== 'object') {
          if (i !== 'page') {
            return i
          }
        }
      }
    }).length
  }

  private created() {
    this.getGiftCards()
  }

  private async getGiftCards() {
    this.giftCardsLoading = true

    const { data } = await GiftCard
      .per(this.paginationGiftCards.perPage)
      .page(this.paginationGiftCards.page)
      .order('name')
      .all()

    this.giftCards.push(...data)

    this.giftCardsLoading = false
  }

  private handleGiftCardSelect(giftCardId: string) {
    this.filters.giftCardId = giftCardId
  }

  refresh() {
    this.showFilters = false
    this.$emit('applyFilters', this.filters)
  }
}
