
import { Component, Vue } from 'vue-property-decorator'
import { CardStock } from '@/models'

@Component({
  name: 'StockHeader'
})
export default class extends Vue {
  panels: any[] = [
    { value: 0, title: this.$t('stock.header.stats.stats1'), currency: false }
  ]

  created(): void {
    this.getNeededStockOrders()
  }

  private async getNeededStockOrders(): Promise<void> {
    const { meta } = await CardStock
      .where({ toBuyCount: { gt: 0 } })
      .stats({ total: 'count' })
      .all()

    this.panels[0].value = meta.stats.total.count
  }
}
