
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { CardStock, GiftCard, Order, Sku } from "@/models";

@Component({
  name: 'StockTable',
  components: {
    StockCard: () => import('./stock-card/StockCard.vue'),
    OrderFormDialog: () => import('@/components/OrderFormDialog/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) listQuery!: any

  private cardStocks: GiftCard[] = []
  private totalCardStocks = 0
  private cardStocksLoading = false

  private dialogFormVisible = false
  private showOverlay = false
  private orderToCreate: Order | null = null

  private pagination = {
    page: 1,
    per: 20
  }

  get sortByNeeded(): boolean {
    return this.listQuery.orderBy === 'delivery'
  }

  created(): void {
    this.getCardStocks()
  }

  private async getCardStocks(): Promise<void> {
    this.cardStocksLoading = true

    let cardStockScope = CardStock.where({ toBuyCount: { gte: 0 } })
    if (this.sortByNeeded) cardStockScope = CardStock.where({ toBuyCount: { gt: 0 } })

    const { data, meta } = await GiftCard
      .includes([{ cardStocks: ['sku'] }, 'defaultGiftCardSeller', 'skus'])
      .where({ id: this.listQuery.giftCardId })
      .page(this.pagination.page)
      .per(this.sortByNeeded ? this.totalCardStocks : this.pagination.per)
      .merge({
        cardStocks: cardStockScope,
        skus: Sku.where({ faceValueFree: false })
      })
      .order(!this.sortByNeeded ? 'name' : '')
      .stats({ total: 'count' })
      .selectExtra(['apiEnabled'])
      .all()

    const skuNotDiscarded = data.filter(gc => gc.cardStocks = gc.cardStocks.filter(cs => !cs.sku.discardedAt))

    if (this.sortByNeeded) {
      this.cardStocks.push(...skuNotDiscarded
        .filter(cs => cs.cardStocks.length > 0)
        .sort((a, b) => {
          return (a.cardStocks.reduce((acc: number, current: CardStock): number => {
            return acc + current.toBuyCount
          }, 0) - b.cardStocks.reduce((acc: number, current: CardStock): number => {
            return acc + current.toBuyCount
          }, 0))
        }).reverse())
    } else {
      this.cardStocks.push(...skuNotDiscarded)
    }
    this.totalCardStocks = meta.stats.total.count
    this.pagination.page += 1
    this.cardStocksLoading = false
  }

  handleOrderToCreate(order: Order): void {
    this.orderToCreate = order
    this.dialogFormVisible = true
    this.showOverlay = false
  }

  handleOrderCreated(order: Order): void {
    this.$notify({
      title: this.$t('stock.notification.success.title') as string,
      message: this.$tc('stock.notification.success.title', 0, { number: order.number }) as string,
      type: 'success',
      duration: 2000
    })
  }

  @Watch('listQuery')
  refresh(): void {
    this.cardStocks = []
    this.pagination.page = 1
    this.totalCardStocks = 0
    this.getCardStocks()
  }
}
